import * as React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Link as RouterLink } from "gatsby";
import Layout from "../components/Layout";
import { MetaData } from "../components/common/meta";
import { PageProps } from "gatsby";
import Twitter from "../images/connect/twitter.inline.svg";
import Github from "../images/connect/github.inline.svg";
import Arrow from "../images/arrow.inline.svg";
import lines from "../images/connect/lines.svg";
import bricks from "../images/connect/bricks.svg";
import side from "../images/line_connect.svg";
import sideBrand from "../images/lineBrand.svg";
const githubUrl = "https://github.com/JumpCrypto";

const socialCard = {
  backgroundColor: "#051535",
  border: "1px solid #4B608D",
  px: { xs: 2, sm: 3 },
  py: 2,
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  "& svg": {
    display: "block",
    ml: "auto",
  },
  "& p": {
    fontWeight: "bold",
    mt: 5.5,
    fontSize: { xs: 18, sm: 24 },
    fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  },
  "&:hover": {
    boxShadow: "0 0 30px 0 rgba(255, 255, 255, .15)",
    "&  svg ": {
      transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
      transform: "translateX(-5px)",
    },
  },
};

const contactLine = {
  backgroundColor: "#051535",
  border: "1px solid #4B608D",
  px: { xs: 2, sm: 3.5, md: 8 },
  py: { xs: 2, sm: 3.5 },
  display: { xs: "block", sm: "flex" },
  justifyContent: "space-between",
  mb: 2,
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  "&:hover": {
    boxShadow: "0 0 30px 0 rgba(255, 255, 255, .15)",
    "&  svg ": {
      transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
      transform: "translateX(5px)",
    },
  },
};

const btn = {
  display: "inline-flex",
  alignItems: "center",
  lineHeight: 1,
  textAlign: "center",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  "& svg": {
    fill: "#1CE7C2",
    ml: 2,
    display: "block",
  },
};

const connect = ({ location }: PageProps) => {
  return (
    <Layout footerDisclaimerOn={false}>
      <MetaData title="Connect" location={location} type="website" />

      <Box
        sx={{
          display: { xs: "none", xl: "block" },
          position: "absolute",
          left: "55px",
          top: "150px",
        }}
        component="img"
        src={side}
        alt=""
      />

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 750,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: 10,
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 3 }} variant="h1">
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Get
            </Box>{" "}
            in touch
          </Typography>
          <Button
            component={RouterLink}
            to={githubUrl}
            variant="outlined"
            startIcon={<Github />}
            sx={{ mt: 3, px: 4, fontSize: 17 }}
          >
            GitHub
          </Button>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          mx: "auto",
          pt: { xs: 2, md: 2 },
          pb: { xs: 2, md: 2 },
          px: 4,
          position: "relative",
          border: "1px solid #4B608D",
          background: "#091A3D",
        }}
      >
        <Typography sx={{
          color: "#8C9AB8",
          fontSize: 14,
          textAlign: "center",
        }}>
          Jump Crypto does not operate any business lines that accept funds from external investors.
          Any person, company, or app purporting to accept external investor funds on behalf of Jump Crypto is fraudulent.
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          mx: "auto",
          pt: { xs: 6, md: 10 },
          pb: { xs: 6, md: 19 },
          position: "relative",
        }}
      >
        <Link
          sx={contactLine}
          href="mailto:careers@jumpcrypto.com"
          component={OutboundLink}
        >
          <Typography sx={{ mb: { xs: 2, sm: 0 } }} variant="h5">
            Looking to{" "}
            <Box
              component="span"
              sx={{ color: "#1CE7C2", mb: { xs: 2, sm: 0 } }}
            >
              work with us?_{" "}
            </Box>
          </Typography>
          <Box component="span" sx={btn}>
            careers@jumpcrypto.com <Arrow />
          </Box>
        </Link>

        <Link sx={contactLine} href="mailto:mediainquiries@jumpcrypto.com">
          <Typography sx={{ mb: { xs: 2, sm: 0 } }} variant="h5">
            Looking to{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              talk to us?_
            </Box>{" "}
          </Typography>
          <Box component="span" sx={btn}>
            mediainquiries@jumpcrypto.com
            <Arrow />
          </Box>
        </Link>


      </Box>

      <Box
        component="img"
        src={lines}
        sx={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default connect;
